import SCSS from '../SVG.module.scss';

const index = () => {
	return (
		<g className={SCSS.Flooring}>
			<line x1='20.44' y1='61.99' x2='95.55' y2='67.24' />
			<polyline points='152.01,68.73 151.84,71.18 125.33,69.33' />
			<polyline points='178.52,70.59 178.35,73.03 200.44,74.58' />
			<line x1='47.27' y1='197.63' x2='72.81' y2='197.63' />
			<line x1='42.52' y1='191.04' x2='42.52' y2='168.54' />
		</g>
	);
};

export default index;
