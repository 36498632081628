import IScene from './IScene';
import {Scheme1, Scheme2} from '../renderings';
import generateHash from 'random-hash';

const Bathroom: IScene = {
	name: 'Bathroom',
	pan: '180deg',
	hotspot: {
		x: 55,
		y: 210,
	},
	images: {
		scheme1: Scheme1.Bathroom,
		scheme2: Scheme2.Bathroom,
	},
	default: {
		latitude: -0.10851420686788993,
		longitude: 3.1393241443570767,
	},
	markers: [
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.5424008907933726,
				longitude: 3.4900835765684457,
			},
			target: {
				name: 'Bedroom',
				view: {
					latitude: -0.05360022820524479,
					longitude: 0.07692006036701442,
				},
			},
		},
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.3731062645704897,
				longitude: 4.283281208057011,
			},
			target: {
				name: 'Living',
				view: {
					latitude: -0.043219355116082436,
					longitude: 1.1174836473411227,
				},
			},
		},
	],
};

export default Bathroom;
