import IScene from './IScene';
import {Scheme1, Scheme2} from '../renderings';
import generateHash from 'random-hash';

const Office: IScene = {
	name: 'Office',
	pan: '90deg',
	hotspot: {
		x: 120,
		y: 210,
	},
	images: {
		scheme1: Scheme1.Office,
		scheme2: Scheme2.Office,
	},
	default: {
		latitude: -0.16980338626671676,
		longitude: 4.71230903186265,
	},
	markers: [
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.39904339930624566,
				longitude: 5.52470733843168,
			},
			target: {
				name: 'Bedroom',
				view: {
					latitude: -0.09386067108078633,
					longitude: 5.965641900449288,
				},
			},
		},
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.5356865468296728,
				longitude: 0.20498420088910804,
			},
			target: {
				name: 'Living',
				view: {
					latitude: -0.05242174205906336,
					longitude: 0.5864055391160717,
				},
			},
		},
	],
};

export default Office;
