import SCSS from '../SVG.module.scss';

const ThinLineworks = () => {
	return (
		<g className={SCSS.ThinLineworks}>
			<line x1='152.76' y1='68.79' x2='177.77' y2='70.54' />
			<rect x='157.91' y='299.53' width='27.02' height='4.13' />
			<circle cx='16.81' cy='210.52' r='0.75' />
			<line x1='72.38' y1='229.24' x2='72.38' y2='240.04' />
			<path
				d='M65.42,238.3c0.34-0.31,0.36-0.84,0.04-1.18c-0.31-0.34-0.84-0.36-1.18-0.04c-0.34,0.31-0.36,0.84-0.04,1.18
			c0.01,0.01,0.03,0.03,0.04,0.04'
			/>
			<path d='M65.6,241.26c0-0.1-0.08-0.18-0.18-0.19' />
			<line x1='65.6' y1='242.38' x2='65.6' y2='241.26' />
			<path d='M65.41,242.57c0.1,0,0.19-0.08,0.19-0.19' />
			<line x1='64.99' y1='242.57' x2='65.41' y2='242.57' />
			<line x1='64.28' y1='242.57' x2='64.7' y2='242.57' />
			<path d='M64.1,242.38c0,0.1,0.08,0.19,0.19,0.19' />
			<line x1='64.1' y1='241.26' x2='64.1' y2='242.38' />
			<path d='M64.28,241.07c-0.1,0-0.18,0.09-0.18,0.19' />
			<line x1='64.7' y1='242.95' x2='64.99' y2='242.95' />
			<line x1='64.7' y1='242.39' x2='64.7' y2='242.95' />
			<line x1='64.99' y1='242.95' x2='64.99' y2='242.39' />
			<line x1='57.31' y1='240.04' x2='64.28' y2='240.04' />
			<line x1='57.31' y1='229.24' x2='57.31' y2='240.04' />
			<line x1='72.38' y1='229.24' x2='57.31' y2='229.24' />
			<line x1='65.42' y1='240.04' x2='72.38' y2='240.04' />
			<path
				d='M46.62,235.96c-0.03,0.41-0.07,0.57-0.11,0.71c-0.04,0.13-0.08,0.24-0.14,0.35
			c-0.06,0.11-0.12,0.23-0.19,0.32c-0.07,0.1-0.13,0.18-0.2,0.25c-0.08,0.08-0.16,0.15-0.26,0.22c-0.12,0.08-0.26,0.17-0.4,0.24
			c-0.16,0.08-0.32,0.14-0.47,0.19c-0.16,0.05-0.31,0.09-0.47,0.12c-0.18,0.04-0.38,0.07-0.57,0.09c-0.24,0.03-0.47,0.05-0.71,0.06
			c-0.31,0.02-0.62,0.03-0.97,0.03c-0.43,0.01-0.91,0.01-1.41-0.01c-0.47-0.01-0.95-0.04-1.34-0.08c-0.32-0.03-0.56-0.07-0.85-0.14
			c-0.19-0.05-0.4-0.11-0.57-0.18c-0.15-0.05-0.27-0.11-0.39-0.17c-0.11-0.06-0.21-0.13-0.33-0.23c-0.12-0.1-0.25-0.23-0.35-0.36
			c-0.1-0.12-0.18-0.24-0.24-0.35c-0.06-0.09-0.11-0.18-0.15-0.28c-0.04-0.09-0.07-0.19-0.09-0.3c-0.02-0.1-0.04-0.19-0.06-0.31
			c-0.02-0.12-0.04-0.27-0.04-0.65'
			/>
			<path
				d='M46.62,229.87c0-0.3-0.01-0.53-0.04-0.74c-0.01-0.1-0.02-0.2-0.04-0.3s-0.03-0.19-0.05-0.28
			c-0.02-0.1-0.03-0.19-0.05-0.29c-0.02-0.1-0.04-0.2-0.05-0.29c-0.02-0.1-0.04-0.2-0.06-0.29c-0.02-0.1-0.04-0.19-0.07-0.29
			c-0.02-0.09-0.05-0.19-0.08-0.28c-0.03-0.09-0.06-0.18-0.09-0.27c-0.03-0.09-0.07-0.17-0.11-0.25c-0.04-0.08-0.08-0.16-0.12-0.24
			c-0.09-0.16-0.18-0.31-0.28-0.45c-0.1-0.14-0.21-0.28-0.31-0.4c-0.11-0.13-0.21-0.24-0.31-0.34s-0.2-0.19-0.29-0.26
			c-0.05-0.04-0.09-0.07-0.13-0.1c-0.04-0.03-0.09-0.06-0.13-0.08c-0.05-0.03-0.09-0.05-0.15-0.08c-0.05-0.03-0.11-0.05-0.18-0.08
			s-0.14-0.06-0.21-0.08c-0.07-0.03-0.15-0.05-0.23-0.08c-0.08-0.03-0.16-0.05-0.24-0.07c-0.08-0.02-0.16-0.04-0.24-0.06
			c-0.16-0.04-0.32-0.06-0.47-0.08c-0.08-0.01-0.15-0.02-0.22-0.02s-0.14-0.01-0.22-0.01c-0.14-0.01-0.29-0.01-0.46-0.01
			c-0.17,0-0.35,0-0.54,0c-0.19,0-0.38,0.01-0.55,0.02c-0.09,0.01-0.17,0.01-0.25,0.02s-0.16,0.02-0.24,0.03
			c-0.08,0.01-0.15,0.02-0.23,0.04c-0.04,0.01-0.07,0.01-0.11,0.02c-0.04,0.01-0.07,0.01-0.11,0.02c-0.07,0.01-0.14,0.03-0.2,0.04
			s-0.12,0.03-0.18,0.04c-0.03,0.01-0.05,0.02-0.08,0.02c-0.03,0.01-0.05,0.02-0.08,0.03c-0.05,0.02-0.1,0.04-0.16,0.07
			s-0.11,0.06-0.17,0.09c-0.06,0.03-0.12,0.07-0.17,0.11c-0.06,0.04-0.12,0.07-0.18,0.11c-0.06,0.04-0.12,0.08-0.17,0.12
			s-0.11,0.09-0.17,0.14c-0.06,0.05-0.12,0.1-0.18,0.16c-0.06,0.06-0.12,0.12-0.18,0.19c-0.06,0.07-0.12,0.14-0.19,0.22
			c-0.12,0.15-0.25,0.32-0.36,0.49c-0.06,0.08-0.11,0.17-0.16,0.25c-0.05,0.08-0.09,0.16-0.13,0.23c-0.04,0.08-0.07,0.15-0.1,0.22
			c-0.03,0.07-0.06,0.14-0.08,0.21c-0.02,0.07-0.05,0.14-0.07,0.2s-0.04,0.14-0.06,0.2s-0.04,0.14-0.06,0.21s-0.04,0.14-0.05,0.21
			c-0.02,0.07-0.04,0.15-0.05,0.23c-0.02,0.08-0.04,0.17-0.05,0.27c-0.01,0.05-0.02,0.1-0.03,0.16s-0.02,0.11-0.03,0.17
			c-0.02,0.12-0.04,0.25-0.05,0.39s-0.03,0.28-0.04,0.42c-0.01,0.14-0.02,0.28-0.02,0.41'
			/>
			<line x1='46.62' y1='229.87' x2='46.62' y2='235.96' />
			<line x1='36.31' y1='229.87' x2='36.31' y2='235.49' />
			<line x1='203.8' y1='250.21' x2='203.79' y2='267.49' />
			<line x1='27.98' y1='190.43' x2='27.98' y2='169.99' />
			<line x1='153' y1='65.42' x2='178.01' y2='67.17' />
			<path d='M201.54,157.34c-1.2,4.07-1.2,8.39,0,12.46' />
			<line x1='218.08' y1='224.07' x2='206.01' y2='224.07' />
			<line x1='206.42' y1='215.91' x2='206.42' y2='219.37' />
			<line x1='206.42' y1='219.61' x2='206.42' y2='223.47' />
			<rect x='206.01' y='215.3' width='12.07' height='17.53' />
			<path d='M209.33,219.37c-0.05-0.28-0.27-0.49-0.55-0.54' />
			<path d='M208.56,218.83c-0.28,0.05-0.5,0.26-0.55,0.54' />
			<path d='M208.01,219.61c0.05,0.28,0.27,0.49,0.55,0.54' />
			<path d='M208.78,220.15c0.28-0.05,0.5-0.26,0.55-0.54' />
			<path d='M209.97,219.37c-0.06-0.63-0.56-1.13-1.19-1.18' />
			<path d='M208.56,218.19c-0.63,0.05-1.13,0.55-1.19,1.18' />
			<path d='M207.37,219.61c0.06,0.63,0.56,1.13,1.19,1.18' />
			<path d='M208.78,220.79c0.63-0.05,1.13-0.55,1.19-1.18' />
			<path d='M208.56,227.6c-0.47,0.05-0.85,0.42-0.9,0.89' />
			<path d='M207.66,228.74c0.06,0.47,0.43,0.84,0.9,0.89' />
			<path d='M208.78,229.63c0.47-0.05,0.85-0.42,0.9-0.89' />
			<path d='M209.69,228.49c-0.06-0.47-0.43-0.84-0.9-0.89' />
			<path d='M208.56,226.96c-0.83,0.05-1.49,0.71-1.55,1.54' />
			<path d='M207.01,228.74c0.06,0.83,0.72,1.48,1.55,1.54' />
			<path d='M208.78,230.28c0.83-0.05,1.49-0.71,1.55-1.54' />
			<path d='M210.33,228.49c-0.06-0.83-0.72-1.48-1.55-1.54' />
			<path d='M214.64,227.22c-0.68,0.05-1.23,0.59-1.29,1.28' />
			<path d='M213.35,228.74c0.06,0.68,0.6,1.22,1.29,1.28' />
			<path d='M214.86,230.02c0.68-0.05,1.23-0.59,1.29-1.28' />
			<path d='M216.15,228.49c-0.06-0.68-0.6-1.22-1.29-1.28' />
			<path d='M214.64,226.76c-0.93,0.06-1.68,0.8-1.74,1.73' />
			<path d='M212.9,228.74c0.06,0.93,0.81,1.67,1.74,1.73' />
			<path d='M214.86,230.47c0.93-0.06,1.68-0.8,1.74-1.73' />
			<path d='M216.6,228.49c-0.06-0.93-0.81-1.67-1.74-1.73' />
			<path d='M214.86,220.14c0.27-0.05,0.49-0.26,0.54-0.53' />
			<path d='M215.4,219.37c-0.05-0.27-0.27-0.49-0.54-0.53' />
			<path d='M214.64,218.84c-0.27,0.05-0.49,0.26-0.54,0.53' />
			<path d='M214.1,219.61c0.05,0.27,0.27,0.49,0.54,0.53' />
			<path d='M214.86,221.5c1.02-0.06,1.84-0.87,1.9-1.89' />
			<path d='M216.76,219.37c-0.06-1.02-0.88-1.83-1.9-1.89' />
			<path d='M214.64,217.48c-1.02,0.06-1.84,0.87-1.9,1.89' />
			<path d='M212.74,219.61c0.06,1.02,0.88,1.83,1.9,1.89' />
			<path
				d='M203.97,220.95c0.11,0.22,0.38,0.31,0.6,0.2c0.22-0.11,0.31-0.38,0.2-0.6c-0.11-0.22-0.38-0.31-0.6-0.2
			c-0.09,0.04-0.16,0.11-0.2,0.2'
			/>
			<line x1='203.72' y1='220.55' x2='204.77' y2='220.55' />
			<line x1='203.72' y1='220.95' x2='204.77' y2='220.95' />
			<path
				d='M203.97,223.14c0.11,0.22,0.38,0.31,0.6,0.2c0.22-0.11,0.31-0.38,0.2-0.6c-0.11-0.22-0.38-0.31-0.6-0.2
			c-0.09,0.04-0.16,0.11-0.2,0.2'
			/>
			<line x1='203.72' y1='222.74' x2='204.77' y2='222.74' />
			<line x1='203.72' y1='223.14' x2='204.77' y2='223.14' />
			<path
				d='M203.97,227.59c0.11,0.22,0.38,0.31,0.6,0.2c0.22-0.11,0.31-0.38,0.2-0.6c-0.11-0.22-0.38-0.31-0.6-0.2
			c-0.09,0.04-0.16,0.11-0.2,0.2'
			/>
			<line x1='203.72' y1='227.59' x2='204.77' y2='227.59' />
			<line x1='203.72' y1='227.19' x2='204.77' y2='227.19' />
			<path
				d='M203.97,225.4c0.11,0.22,0.38,0.31,0.6,0.2c0.22-0.11,0.31-0.38,0.2-0.6c-0.11-0.22-0.38-0.31-0.6-0.2
			c-0.09,0.04-0.16,0.11-0.2,0.2'
			/>
			<line x1='203.72' y1='225.4' x2='204.77' y2='225.4' />
			<line x1='203.72' y1='224.99' x2='204.77' y2='224.99' />
			<rect x='214.86' y='224.67' width='2.93' height='3.82' />
			<rect x='214.86' y='228.74' width='2.93' height='3.49' />
			<rect x='211.81' y='228.74' width='2.83' height='3.49' />
			<rect x='211.81' y='224.67' width='2.83' height='3.82' />
			<rect x='208.78' y='224.67' width='2.81' height='3.82' />
			<rect x='208.78' y='228.74' width='2.81' height='3.49' />
			<rect x='206.42' y='228.74' width='2.14' height='3.49' />
			<rect x='206.42' y='224.67' width='2.14' height='3.82' />
			<rect x='206.42' y='219.61' width='2.14' height='3.85' />
			<rect x='206.42' y='215.91' width='2.14' height='3.46' />
			<rect x='208.78' y='215.91' width='2.81' height='3.46' />
			<rect x='208.78' y='219.61' width='2.81' height='3.85' />
			<rect x='211.81' y='219.61' width='2.83' height='3.85' />
			<rect x='211.81' y='215.91' width='2.83' height='3.46' />
			<rect x='214.86' y='215.91' width='2.93' height='3.46' />
			<rect x='214.86' y='219.61' width='2.93' height='3.85' />
			<path d='M201.54,217.84c-1.2,4.07-1.2,8.39,0,12.46' />
			<circle cx='217.29' cy='186.92' r='0.19' />
			<line x1='217.95' y1='186.34' x2='216.62' y2='186.34' />
			<polyline points='215.42,184.72 215.42,176.14 204.17,176.14 204.17,194.14 215.42,194.14 215.42,185.56' />
			<path
				d='M211.61,184.85c-0.16-0.38-0.6-0.56-0.98-0.41c-0.38,0.16-0.56,0.6-0.41,0.98c0.16,0.38,0.6,0.56,0.98,0.41
			c0.18-0.08,0.33-0.22,0.41-0.41'
			/>
		</g>
	);
};

export default ThinLineworks;
