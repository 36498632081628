import SCSS from '../SVG.module.scss';

const Dashed = () => {
	return (
		<g className={SCSS.Dashed}>
			<line x1='210.92' y1='249.49' x2='210.92' y2='247.99' />
			<line x1='210.92' y1='245.82' x2='210.92' y2='236.03' />
			<line x1='210.92' y1='234.94' x2='210.92' y2='233.44' />
			<line x1='201.92' y1='172.57' x2='203.42' y2='172.57' />
			<line x1='205.79' y1='172.57' x2='216.48' y2='172.57' />
			<polyline points='217.67,172.57 219.17,172.57 219.17,171.07' />
			<line x1='219.17' y1='169.31' x2='219.17' y2='156.95' />
			<polyline points='219.17,156.07 219.17,154.57 217.67,154.57' />
			<line x1='215.29' y1='154.57' x2='204.61' y2='154.57' />
			<line x1='203.42' y1='154.57' x2='201.92' y2='154.57' />
			<line x1='219.92' y1='233.44' x2='218.42' y2='233.44' />
			<line x1='216.67' y1='233.44' x2='208.79' y2='233.44' />
			<polyline points='207.92,233.44 206.42,233.44 206.42,231.94' />
			<line x1='206.42' y1='230.09' x2='206.42' y2='217.12' />
			<polyline points='206.42,216.19 206.42,214.69 207.92,214.69' />
			<line x1='209.67' y1='214.69' x2='217.54' y2='214.69' />
			<line x1='218.42' y1='214.69' x2='219.92' y2='214.69' />
			<line x1='210.92' y1='214.69' x2='210.92' y2='213.19' />
			<line x1='210.92' y1='211.16' x2='210.92' y2='156.34' />
			<line x1='210.92' y1='155.32' x2='210.92' y2='153.82' />
			<line x1='111.2' y1='140.17' x2='111.2' y2='138.67' />
			<line x1='111.2' y1='136.61' x2='111.2' y2='70.86' />
			<line x1='111.2' y1='69.83' x2='111.2' y2='68.33' />
			<line x1='118.7' y1='152.92' x2='117.2' y2='152.92' />
			<line x1='115.01' y1='152.92' x2='94.27' y2='152.92' />
			<line x1='93.18' y1='152.92' x2='91.68' y2='152.92' />
		</g>
	);
};

export default Dashed;
