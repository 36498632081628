import SCSS from '../SVG.module.scss';

export const Walls = () => {
	return (
		<g className={SCSS.Walls}>
			<polygon
				points='223.17,73.71 200.61,72.13 200.44,74.58 211.65,75.36 211.65,107.87 219.92,107.87 219.92,267.49 
			189.36,267.49 189.36,299.53 184.93,299.53 184.93,303.66 192.61,303.66 192.61,270.74 223.17,270.74'
			/>
			<polygon
				points='1.56,58.21 20.61,59.55 20.44,61.99 9.15,61.2 9.15,162.82 59.97,162.82 59.97,166.3 44.26,166.3 
			44.26,168.54 40.77,168.54 40.77,166.3 4.81,166.3 4.81,194.12 40.77,194.12 40.77,191.04 44.26,191.04 44.26,195.89 
			47.27,195.89 47.27,199.37 22.72,199.37 22.72,197.16 10.91,197.16 10.91,199.37 4.81,199.37 4.81,244.37 76.89,244.37 
			76.89,199.37 72.81,199.37 72.81,195.89 104.01,195.89 104.01,196.33 76.89,196.33 76.89,198.93 104.01,198.93 104.01,199.37 
			82.14,199.37 82.14,247.8 154.57,247.8 154.57,299.53 157.91,299.53 157.91,303.66 151.33,303.66 151.33,251.04 1.56,251.04 		'
			/>
			<polygon
				points='130.04,199.37 126.96,199.37 126.96,195.89 133.52,195.89 133.52,224.85 133.08,224.85 
			133.08,198.99 130.48,198.99 130.48,224.85 130.04,224.85 		'
			/>
			<polygon
				points='95.72,64.8 95.55,67.24 97.2,67.36 97.2,76.45 100.69,76.45 100.69,67.6 118.7,68.86 118.7,140.17 
			100.69,140.17 100.69,130.48 97.2,130.48 97.2,140.17 88.2,140.17 88.2,162.82 85.51,162.82 85.51,166.3 96.18,166.3 
			96.18,162.82 91.68,162.82 91.68,145.42 118.7,145.42 118.7,162.82 114.2,162.82 114.2,166.3 122.18,166.3 122.18,69.11 
			125.33,69.33 125.5,66.88 		'
			/>
		</g>
	);
};
export default Walls;
