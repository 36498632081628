import SCSS from '../SVG.module.scss';

const Backdrop = () => {
	return (
		<g id='Backdrop' className={SCSS.Backdrop}>
			<polyline
				className={SCSS.Balcony}
				id='Balcony'
				points='1.56,0.31 202.16,41.57 201.09,68.96 1.56,54.86'
			/>
			<polyline
				className={SCSS.Interiors}
				id='Interior'
				points='223.54,70.35 223.31,73.72 223.31,270.74 192.61,270.74 192.61,303.66 151.33,303.66 
		151.33,251.04 1.56,251.04 1.56,54.86'
			/>
		</g>
	);
};

export default Backdrop;
