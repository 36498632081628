import IImage from './IImage';
import Scheme_1_Bathroom from './The_Grand_Unit_A-Scheme_01-Bathroom.jpg';
import Scheme_1_Bedroom from './The_Grand_Unit_A-Scheme_01-Bedroom.jpg';
import Scheme_1_Kitchen from './The_Grand_Unit_A-Scheme_01-Kitchen.jpg';
import Scheme_1_Living from './The_Grand_Unit_A-Scheme_01-Living.jpg';
import Scheme_1_Office from './The_Grand_Unit_A-Scheme_01-Office.jpg';

import Scheme_2_Bathroom from './The_Grand_Unit_A-Scheme_02-Bathroom.jpg';
import Scheme_2_Bedroom from './The_Grand_Unit_A-Scheme_02-Bedroom.jpg';
import Scheme_2_Kitchen from './The_Grand_Unit_A-Scheme_02-Kitchen.jpg';
import Scheme_2_Living from './The_Grand_Unit_A-Scheme_02-Living.jpg';
import Scheme_2_Office from './The_Grand_Unit_A-Scheme_02-Office.jpg';

export const Scheme1: IImage = {
	Bathroom: Scheme_1_Bathroom,
	Bedroom: Scheme_1_Bedroom,
	Kitchen: Scheme_1_Kitchen,
	Living: Scheme_1_Living,
	Office: Scheme_1_Office,
};

export const Scheme2: IImage = {
	Bathroom: Scheme_2_Bathroom,
	Bedroom: Scheme_2_Bedroom,
	Kitchen: Scheme_2_Kitchen,
	Living: Scheme_2_Living,
	Office: Scheme_2_Office,
};
