import SCSS from '../SVG.module.scss';

const Furniture = () => {
	return (
		<g className={SCSS.Furniture}>
			<rect x='155.7' y='185.06' width='22.5' height='35.62' />
			<rect x='122.18' y='82.86' width='24' height='65.27' />
			<polyline points='143.93,82.86 143.93,85.86 125.19,85.86 125.19,145.12 143.93,145.12 143.93,148.13' />
			<line x1='146.18' y1='125.15' x2='125.19' y2='125.09' />
			<line x1='146.18' y1='105.83' x2='125.19' y2='105.83' />
			<rect x='125.79' y='86.53' width='4.5' height='57.93' />
			<rect x='160.04' y='103.24' width='22.5' height='22.5' />
			<rect x='211.64' y='108.07' width='9' height='45.61' />
			<rect x='215.73' y='112.63' width='1.12' height='36.49' />
			<polyline points='215.73,133.92 214.04,133.92 214.04,127.84 215.73,127.84' />
			<polyline points='216.85,127.84 218.54,127.84 218.54,133.92 216.85,133.92' />
			<rect x='9.15' y='86.96' width='0.75' height='45.75' />
			<polyline points='23.75,87.34 69.15,87.34 69.15,132.34 41.09,132.34' />
			<rect x='9.15' y='133.63' width='13.29' height='13.29' />
			<rect x='9.15' y='72.76' width='13.29' height='13.29' />
			<polyline points='18.43,90.71 11.33,90.71 11.33,107.96 22.01,107.96' />
			<polyline points='23.18,113.58 23.18,128.96 11.33,128.96 11.33,111.34 22.71,111.34' />
			<polygon points='17.65,86.96 23.61,86.96 41.23,132.71 27.15,132.71' />
			<polyline points='27.07,132.34 9.9,132.34 9.9,87.34 17.73,87.34' />
			<rect x='149.55' y='188.63' width='6.15' height='12' />
			<line x1='150.52' y1='200.63' x2='150.52' y2='188.63' />
			<rect x='149.55' y='205.13' width='6.15' height='12' />
			<line x1='150.52' y1='205.13' x2='150.52' y2='217.13' />
			<rect x='178.2' y='188.63' width='6.15' height='12' />
			<line x1='183.37' y1='200.63' x2='183.37' y2='188.63' />
			<rect x='178.2' y='205.13' width='6.15' height='12' />
			<line x1='183.37' y1='205.13' x2='183.37' y2='217.13' />
			<rect x='82.14' y='199.37' width='18' height='48.43' />
			<rect x='100.14' y='216.83' width='8.44' height='1.5' />
			<rect x='107.83' y='218.33' width='1.5' height='10.5' />
			<rect x='100.14' y='228.83' width='8.44' height='1.5' />
			<rect x='91.55' y='216.83' width='4.5' height='13.5' />
			<rect x='92.3' y='217.58' width='3' height='1.73' />
			<rect x='92.3' y='219.95' width='3' height='9.64' />
			<polyline points='85.93,221.33 84.24,221.33 84.24,225.46 85.93,225.46' />
			<rect x='85.93' y='216.83' width='1.12' height='13.5' />
			<polyline points='87.05,225.46 88.74,225.46 88.74,221.33 87.05,221.33' />
		</g>
	);
};

export default Furniture;
