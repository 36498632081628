import IScene from './IScene';
import {Scheme1, Scheme2} from '../renderings';
import generateHash from 'random-hash';

const Bedroom: IScene = {
	name: 'Bedroom',
	pan: '0deg',
	hotspot: {
		x: 70,
		y: 150,
	},
	images: {
		scheme1: Scheme1.Bedroom,
		scheme2: Scheme2.Bedroom,
	},
	default: {
		latitude: -0.05360022820524479,
		longitude: 0.07692006036701442,
	},
	markers: [
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.7290937213724962,
				longitude: 2.8693204544669735,
			},
			target: {
				name: 'Bathroom',
				view: {
					latitude: -0.10851420686788993,
					longitude: 3.1393241443570767,
				},
			},
		},
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.4701280784481776,
				longitude: 2.3440533915273503,
			},
			target: {
				name: 'Office',
				view: {
					latitude: -0.18189782408740918,
					longitude: 2.378436513513628,
				},
			},
		},
	],
};

export default Bedroom;
