import IScene from './IScene';
import {Scheme1, Scheme2} from '../renderings';
import generateHash from 'random-hash';

const Living: IScene = {
	name: 'Living',
	pan: '0deg',
	hotspot: {
		x: 170,
		y: 150,
	},
	images: {
		scheme1: Scheme1.Living,
		scheme2: Scheme2.Living,
	},
	default: {
		latitude: -0.040918758380337206,
		longitude: 0.06347002539532094,
	},
	markers: [
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.31476667320010177,
				longitude: 3.0858245220608427,
			},
			target: {
				name: 'Kitchen',
				view: {
					latitude: -0.07377523969921818,
					longitude: 3.1055582204635717,
				},
			},
		},
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.5173410723344829,
				longitude: 3.9843798349522705,
			},
			target: {
				name: 'Office',
				view: {
					latitude: 0.04024778696896769,
					longitude: 1.2087231794732551,
				},
			},
		},
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.35776744848012276,
				longitude: 4.329568124997258,
			},
			target: {
				name: 'Bathroom',
				view: {
					latitude: -0.03707063733372751,
					longitude: 3.7968852776063367,
				},
			},
		},
	],
};

export default Living;
