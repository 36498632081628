import IScene from './IScene';
import {Scheme1, Scheme2} from '../renderings';
import generateHash from 'random-hash';

const Kitchen: IScene = {
	name: 'Kitchen',
	pan: '0deg',
	hotspot: {
		x: 180,
		y: 250,
	},
	images: {
		scheme1: Scheme1.Kitchen,
		scheme2: Scheme2.Kitchen,
	},
	default: {
		latitude: -0.0142589093971619,
		longitude: 6.196026553396299,
	},
	markers: [
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.3367726142517402,
				longitude: 6.040165399264867,
			},
			target: {
				name: 'Living',
				view: {
					latitude: -0.040918758380337206,
					longitude: 0.06347002539532094,
				},
			},
		},
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.5093459740946997,
				longitude: 5.17206038303008,
			},
			target: {
				name: 'Office',
				view: {
					latitude: -0.137543821281636,
					longitude: 5.564966260161883,
				},
			},
		},
	],
};

export default Kitchen;
