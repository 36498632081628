import SCSS from '../SVG.module.scss';

const Lineworks = () => {
	return (
		<g className={SCSS.Lineworks}>
			<polygon points='201,42.48 200.11,68.09 201.23,68.13 202.16,41.57 1.56,0.31 1.56,1.46' />
			<line x1='200.1' y1='68.71' x2='200.14' y2='68.09' />
			<rect x='131.04' y='201.18' width='1.48' height='25.54' />
			<rect x='80.34' y='196.89' width='25.54' height='1.48' />
			<polygon points='1.56,54.83 1.56,58.21 20.19,59.52 20.42,56.15' />

			<rect
				x='19.36'
				y='57.14'
				transform='matrix(0.0698 -0.9976 0.9976 0.0698 -38.1612 74.8479)'
				width='3.38'
				height='1.5'
			/>

			<rect
				x='41.46'
				y='58.68'
				transform='matrix(0.0698 -0.9976 0.9976 0.0698 -19.1516 98.3228)'
				width='3.38'
				height='1.5'
			/>

			<rect
				x='67.97'
				y='60.53'
				transform='matrix(0.0698 -0.9976 0.9976 0.0698 3.66 126.4928)'
				width='3.38'
				height='1.5'
			/>

			<rect
				x='94.48'
				y='62.39'
				transform='matrix(0.0698 -0.9976 0.9976 0.0698 26.4715 154.6627)'
				width='3.38'
				height='1.5'
			/>

			<rect
				x='123.93'
				y='64.45'
				transform='matrix(0.0698 -0.9976 0.9976 0.0698 51.8177 185.9626)'
				width='3.38'
				height='1.5'
			/>

			<rect
				x='150.44'
				y='66.3'
				transform='matrix(0.0698 -0.9976 0.9976 0.0698 74.6293 214.1326)'
				width='3.38'
				height='1.5'
			/>

			<rect
				x='176.95'
				y='68.15'
				transform='matrix(0.0698 -0.9976 0.9976 0.0698 97.4409 242.3025)'
				width='3.38'
				height='1.5'
			/>

			<rect
				x='199.04'
				y='69.7'
				transform='matrix(0.0698 -0.9976 0.9976 0.0698 116.4505 265.7775)'
				width='3.38'
				height='1.5'
			/>

			<rect
				x='220.99'
				y='71.23'
				transform='matrix(0.0698 -0.9976 0.9976 0.0698 135.3336 289.0962)'
				width='3.38'
				height='1.5'
			/>

			<rect
				x='210.02'
				y='60.97'
				transform='matrix(0.0698 -0.9976 0.9976 0.0698 125.8921 277.4369)'
				width='3.38'
				height='20.5'
			/>

			<rect
				x='137.19'
				y='53.59'
				transform='matrix(0.0698 -0.9976 0.9976 0.0698 63.2235 200.0476)'
				width='3.38'
				height='25.07'
			/>

			<rect
				x='109.2'
				y='50.15'
				transform='matrix(0.0698 -0.9976 0.9976 0.0698 39.1446 170.3127)'
				width='3.38'
				height='28.03'
			/>

			<rect
				x='81.22'
				y='49.67'
				transform='matrix(0.0698 -0.9976 0.9976 0.0698 15.0658 140.5777)'
				width='3.38'
				height='25.07'
			/>

			<rect
				x='54.71'
				y='47.82'
				transform='matrix(0.0698 -0.9976 0.9976 0.0698 -7.7458 112.4078)'
				width='3.38'
				height='25.07'
			/>

			<rect
				x='30.41'
				y='48.33'
				transform='matrix(0.0698 -0.9976 0.9976 0.0698 -28.6564 86.5853)'
				width='3.38'
				height='20.65'
			/>

			<rect
				x='188'
				y='59.35'
				transform='matrix(0.0698 -0.9976 0.9976 0.0698 106.9457 254.04)'
				width='3.38'
				height='20.65'
			/>
			<line x1='179.39' y1='68.96' x2='199.98' y2='70.4' />
			<line x1='126.37' y1='65.25' x2='151.38' y2='67' />
			<line x1='70.4' y1='61.34' x2='95.42' y2='63.09' />
			<line x1='43.89' y1='59.48' x2='68.91' y2='61.23' />
			<line x1='21.8' y1='57.94' x2='42.4' y2='59.38' />

			<rect
				x='176.98'
				y='44'
				transform='matrix(0.0698 -0.9976 0.9976 0.0698 122.4588 220.0689)'
				width='4.5'
				height='0.75'
			/>

			<rect
				x='175.55'
				y='64.53'
				transform='matrix(0.0698 -0.9976 0.9976 0.0698 100.6435 237.7346)'
				width='4.49'
				height='0.75'
			/>

			<rect
				x='104.39'
				y='158.98'
				transform='matrix(0.2588 -0.9659 0.9659 0.2588 -84.4011 225.9857)'
				width='1.33'
				height='18.01'
			/>
			<rect x='84.18' y='137.27' width='1.33' height='25.54' />
			<rect x='97.73' y='90.33' width='1.48' height='27.77' />
			<rect x='99.21' y='76.45' width='1.48' height='27.77' />

			<rect
				x='35.28'
				y='179.34'
				transform='matrix(0.2588 -0.9659 0.9659 0.2588 -139.387 178.3593)'
				width='22.5'
				height='1.33'
			/>
			<rect x='71.48' y='199.37' width='1.33' height='25.54' />
			<rect x='157.91' y='272.51' width='1.33' height='27.02' />
			<rect x='1.19' y='3.36' width='0.75' height='49.63' />
			<rect x='0.25' y='2.89' width='2.62' height='0.47' />
			<rect x='0.25' y='53' width='2.62' height='0.47' />
			<line x1='1.56' y1='1.46' x2='1.56' y2='2.89' />
			<line x1='1.56' y1='54.83' x2='1.56' y2='53.46' />
			<rect x='4.81' y='199.37' width='24' height='45' />
			<path
				d='M7.19,222.95l2.07,17.19c0.14,1.13,1.1,1.98,2.23,1.98h10.63c1.14,0,2.1-0.85,2.23-1.98l2.07-17.19
			c0.09-0.71,0.09-1.44,0-2.15l-2.07-17.19c-0.14-1.13-1.1-1.98-2.23-1.98H11.49c-1.14,0-2.1,0.85-2.23,1.98L7.19,220.8
			C7.1,221.51,7.1,222.23,7.19,222.95z'
			/>
			<line x1='72.94' y1='228.6' x2='72.94' y2='240.6' />
			<line x1='64.46' y1='237.84' x2='65.23' y2='237.84' />
			<path d='M65.42,239.14c0-0.1-0.08-0.19-0.19-0.19' />
			<line x1='64.46' y1='238.96' x2='65.23' y2='238.96' />
			<path d='M64.46,238.96c-0.1,0-0.19,0.08-0.19,0.19' />
			<line x1='65.42' y1='238.03' x2='65.42' y2='242.25' />
			<path d='M65.42,238.03c0-0.1-0.08-0.19-0.19-0.19' />
			<line x1='64.46' y1='237.84' x2='65.23' y2='237.84' />
			<path d='M64.46,237.84c-0.1,0-0.19,0.08-0.19,0.19' />
			<line x1='64.28' y1='242.25' x2='64.28' y2='238.03' />
			<path d='M65.27,242.39c0.08,0,0.14-0.06,0.14-0.14' />
			<line x1='64.42' y1='242.39' x2='65.27' y2='242.39' />
			<path d='M64.28,242.25c0,0.08,0.06,0.14,0.14,0.14' />
			<line x1='56.75' y1='240.6' x2='64.28' y2='240.6' />
			<line x1='56.75' y1='228.6' x2='56.75' y2='240.6' />
			<line x1='72.94' y1='228.6' x2='56.75' y2='228.6' />
			<line x1='65.42' y1='240.6' x2='72.94' y2='240.6' />
			<path
				d='M46.99,229.87c0-0.67-0.07-1.06-0.14-1.48c-0.09-0.54-0.2-1.12-0.4-1.65c-0.21-0.55-0.53-1.04-0.86-1.43
			c-0.25-0.3-0.5-0.55-0.73-0.73c-0.19-0.15-0.36-0.24-0.6-0.35c-0.23-0.1-0.51-0.2-0.8-0.28c-0.31-0.08-0.62-0.14-0.91-0.17
			c-0.3-0.03-0.57-0.03-0.91-0.03c-0.32,0-0.7,0-1.04,0.03c-0.32,0.03-0.61,0.08-0.87,0.12c-0.22,0.04-0.43,0.08-0.63,0.15
			c-0.21,0.08-0.41,0.19-0.61,0.32c-0.29,0.18-0.58,0.38-0.89,0.71c-0.33,0.35-0.69,0.84-0.92,1.26c-0.22,0.41-0.32,0.76-0.41,1.1
			c-0.08,0.29-0.15,0.56-0.23,1.04c-0.07,0.39-0.13,0.92-0.13,1.4'
			/>
			<line x1='35.93' y1='229.87' x2='35.93' y2='238.58' />
			<polyline points='28.81,238.58 52.81,238.58 52.81,227.5 76.89,227.5' />
			<line x1='46.99' y1='229.87' x2='46.99' y2='238.58' />
			<rect x='201.92' y='249.49' width='18' height='0.72' />
			<rect x='7.81' y='169.99' width='22.69' height='20.44' />
			<polyline points='201.92,267.49 201.92,153.82 219.92,153.81' />
			<rect x='201.54' y='154.57' width='0.38' height='18' />
			<path d='M201.54,155.58c-1.95,5.15-1.95,10.84,0,15.99' />
			<line x1='109.7' y1='140.17' x2='109.7' y2='68.23' />
			<line x1='118.7' y1='154.42' x2='91.68' y2='154.42' />
			<path
				d='M203.04,232.84V215.3c0-0.13,0.1-0.23,0.23-0.23h14.8c0.13,0,0.23,0.1,0.23,0.23v17.54
			c0,0.13-0.1,0.23-0.23,0.23h-14.8C203.15,233.07,203.04,232.97,203.04,232.84z'
			/>
			<circle cx='204.37' cy='220.75' r='0.68' />
			<circle cx='204.37' cy='222.94' r='0.68' />
			<circle cx='204.37' cy='227.39' r='0.68' />
			<circle cx='204.37' cy='225.19' r='0.68' />
			<rect x='201.54' y='215.07' width='0.38' height='18' />
			<path d='M201.54,216.07c-1.95,5.15-1.95,10.84,0,15.99' />
			<rect x='202.67' y='174.64' width='15.75' height='21' />
			<path d='M217.29,185.56c0.23,0,0.42-0.19,0.42-0.42c0-0.23-0.19-0.42-0.42-0.42' />
			<path
				d='M216.78,185.56c0.23,0.28,0.65,0.32,0.93,0.09c0.28-0.23,0.32-0.65,0.09-0.93s-0.65-0.32-0.93-0.09
			c-0.03,0.03-0.06,0.06-0.09,0.09'
			/>
			<polyline points='217.29,185.56 217.29,185.56 211.92,185.56' />
			<line x1='211.92' y1='184.72' x2='217.29' y2='184.72' />
			<polyline points='217.95,187.19 216.62,187.19 216.62,185.56' />
			<line x1='217.95' y1='187.19' x2='217.95' y2='185.14' />
			<path d='M211.92,184.72c-0.23,0-0.42,0.19-0.42,0.42c0,0.23,0.19,0.42,0.42,0.42' />
		</g>
	);
};

export default Lineworks;
