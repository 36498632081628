import SVG from '../SVG.module.scss';

const DoorSwings = () => {
	return (
		<g className={SVG.DoorSwings}>
			<rect
				x='170.47'
				y='54.27'
				transform='matrix(0.0698 -0.9976 0.9976 0.0698 111.5484 228.9039)'
				width='16.09'
				height='0.75'
			/>
			<path d='M179.01,42.11c-13.55-0.54-25.07,9.78-26.01,23.31' />
			<path d='M113.93,169.68c0.22-1.11,0.31-2.25,0.27-3.38' />
			<path d='M84.18,137.27c-13.57,0.74-24.2,11.95-24.21,25.54' />
			<path d='M48.8,168.97c-1.49-0.31-3.02-0.45-4.54-0.42' />
			<path d='M47.27,199.37c0.01,13.59,10.64,24.8,24.21,25.54' />
			<path d='M184.93,299.53c-0.01-14.41-11.3-26.28-25.69-27.02' />
		</g>
	);
};

export default DoorSwings;
